const form = document.querySelector("form");
const signature = document.querySelector("#signature");
const copyButton = document.querySelector("#copyButton");
const results = document.querySelector("#results");

form.addEventListener("submit", function (event) {
  event.preventDefault();
  generateSignature();
});

async function copyToClipboard(html) {
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
      }),
    ]);
    console.log("Signature HTML copied to clipboard");
  } catch (err) {
    console.error("Failed to copy signature HTML: ", err);
  }
}

// Get the URL parameters and populate the form
const urlParams = new URLSearchParams(window.location.search);
const fields = ["name", "pronouns", "title", "phone", "department"];
fields.forEach((field) => {
  const value = urlParams.get(field);
  if (value !== null) {
    const input = form.elements[field];
    if (input) {
      if (input.type === "checkbox" || input.type === "radio") {
        input.checked = true;
      } else if (input.tagName === "SELECT") {
        const option = input.querySelector(`option[value="${value}"]`);
        if (option) {
          option.selected = true;
        }
      } else {
        input.value = value;
      }
    }
  }
});

// Update the URL when the form is changed
function updateUrl() {
  const params = new URLSearchParams();
  fields.forEach((field) => {
    const value = form.elements[field].value;
    if (value) {
      params.set(field, value);
    }
  });
  const newUrl = `${location.pathname}?${params.toString()}`;
  history.pushState({}, "", newUrl);
}

// If there are any parameters in the URL, generate the signature immediately
if (urlParams.toString()) {
  generateSignature();
}

function generateSignature() {
  let name = form.elements["name"].value;
  let pronouns = form.elements["pronouns"].value;
  let title = form.elements["title"].value;
  const phone = form.elements["phone"].value.replace(/\D/g, ""); // extract only digits
  const social = form.elements["social"].value;
  const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  // Update department-select when department is set
  const departmentSelect = document.getElementById("department-select");
  const departmentOther = document.getElementById("department-other");
  const departmentInput = document.getElementById("department");

  if (departmentInput.value !== "") {
    const option = departmentSelect.querySelector(
      `option[value="${departmentInput.value}"]`
    );
    if (option) {
      option.selected = true;
    } else {
      departmentSelect.value = "other";
      departmentOther.classList.remove("hidden");
    }
  }

  departmentSelect.addEventListener("change", function () {
    if (departmentSelect.value === "other") {
      departmentOther.classList.remove("hidden");
      departmentInput.value = ""; // Clear the department input value
    } else {
      departmentOther.classList.add("hidden");
      departmentInput.value = departmentSelect.value; // Set the department input value to the selected option
    }
  });

  departmentOther.addEventListener("input", function () {
    departmentInput.value = departmentOther.value; // Set the department input value to the entered value
  });

  let department = form.elements["department"].value;

  let noticeInput = document.getElementById("notice");
  const noticeCustom = document.getElementById("notice-custom");

  // event listener for radio buttons with name="notice-option"
  document.querySelectorAll('input[name="notice-option"]').forEach((el) => {
    el.addEventListener("change", function () {
      if (el.value == "custom") {
        noticeCustom.disabled = false;
        noticeInput.value = noticeCustom.value; // Set the notice input value to the entered value
      } else {
        noticeCustom.value = "";
        noticeCustom.disabled = true;
        noticeInput.value = document.querySelector(
          'input[name="notice-option"]:checked'
        ).value;
      }
    });
  });

  noticeCustom.addEventListener("input", function () {
    noticeInput.value = noticeCustom.value; // Set the notice input value to the entered value
  });

  let notice = form.elements["notice"].value;

  if (department) {
    title = `${title}, ${department}`;
  }
  let phoneHTML = "";
  if (pronouns) {
    name = `${name} (${pronouns})`;
  }
  if (phone) {
    phoneHTML = `<tr> <td height="22"><img src="https://icn.brandly.com/30/000000/plain/cell.png" width="15"> <a href="tel:${formattedPhone}" style="text-decoration:none;color:#000000;">${formattedPhone}</a></td> </tr>`;
  }
  let socialHTML = "";
  if (social == "show") {
    socialHTML = `<a href="https://twitter.com/campaignzero" style="text-decoration:none;color:inherit;" target="_blank"><img src="https://icn.brandly.com/44/000000/plain/twitter.png" width="22"></a><a href="https://facebook.com/campaignzero" style="text-decoration:none;color:inherit;" target="_blank"><img src="https://icn.brandly.com/44/000000/plain/facebook.png" width="22"></a><a href="https://instagram.com/campaignzero" style="text-decoration:none;color:inherit;" target="_blank"><img src="https://icn.brandly.com/44/000000/plain/instagram.png" width="22"></a><a href="https://linkedin.com/company/we-the-protesters-campaign-zero/" style="text-decoration:none;color:inherit;" target="_blank"><img src="https://icn.brandly.com/44/000000/plain/linkedin.png" width="22"></a>`;
  }
  let noticeHTML = "";
  if (notice) {
    noticeHTML = `<p style="margin-top:1rem;font-size:11px;color:#000000;">${notice}</p>`;
  }

  // Keep this minified
  const signatureHTML = `<p>&nbsp;</p><table width="410" style="color:#000000;font-size:11px;font-family:Arial,sans-serif;"><tbody><tr><td><table width="400"><tbody><tr><td style="font-size:13px;" width="210"><b style="color:#e87b58;">${name}</b><br/><span style="font-size:11px;padding-bottom:10px;">${title}</span></td><td align="right"><img src="https://czbrand.azureedge.net/czbrand/logo/campaignzero-logo.png" style="border-radius:0;height:39px;" height="39"></td></tr></tbody></table><div style="width:400px;border-bottom:3px solid #e87b58;margin:1em 0;"></div><table width="400"><tbody><tr><td valign="top"><table><tbody style="font-size:13px;color:#000000">${phoneHTML}<tr><td height="22"><img src="https://icn.brandly.com/30/000000/plain/website.png" width="15"> <a href="https://campaignzero.org" style="text-decoration:none;color:#000000;">campaignzero.org</a></td> </tr></tbody></table></td><td align="right" valign="top">${socialHTML}</td></tr></tbody></table></td></tr></tbody></table>${noticeHTML}`;
  signature.innerHTML = signatureHTML;
  copyButton.style.display = "block";
  results.classList.remove("hidden");
  updateUrl();
}
